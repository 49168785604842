<template>
  <b-container v-if="task" class="ml-3 mr-3">
    <b-row class="a-profile-subpage-title">
      <b-col cols="12" sm="7" class="order-1 order-sm-0">
        <div class="d-flex align-items-center">
          <div
            class="z-icon-wrapper border cp"
            @click="$router.push(`/triedy/${$route.params.id}`)"
          >
            <svg-arrow-left-icon />
          </div>
          <router-link :to="courseUrl">
            <img
              class="thumbnail ml-2"
              :src="courseThumbnail(task.attributes.course.data)"
            />
          </router-link>
          <div>
            <h1 v-if="task" class="ml-3">
              {{ task.attributes.course.data.attributes.name }}
            </h1>
          </div>
        </div>
      </b-col>
      <b-col cols="12" sm="5" class="order-0 order-sm-1">
        <div class="d-flex flex-md-row-reverse">
          <p class="name-p light-blue" v-if="task">
            Trieda:
            <b
              class="group-name fw-bold cp"
              @click="$router.replace({ path: `/triedy/${$route.params.id}` })"
            >
              {{ task.attributes.group.data.attributes.name }} -
              {{ task.attributes.group.data.attributes.subject }}
            </b>
          </p>
        </div>
      </b-col>
    </b-row>
    <b-tabs class="mb-5 mt-3">
      <b-tab title="Informácie o úlohe">
        <b-row class="mt-4" v-if="task">
          <b-col class="cell-border mt-3 mr-3">
            <p class="name-p mb-0">Stav</p>
            <p v-if="active" class="status-p text-success m-0">
              <svg-bullet-icon /> Aktívny
            </p>
            <p v-else class="status-p text-danger m-0">
              <svg-bullet-icon /> Neaktívny
            </p>
          </b-col>
          <b-col class="cell-border mt-3 mr-3">
            <p class="name-p mb-0">Termín zadania úlohy</p>
            <b class="m-0">{{ formatDate(task.attributes.startsAt) }}</b>
          </b-col>
          <b-col class="cell-border mt-3 mr-3">
            <p class="name-p mb-0">Termín splnenia úlohy</p>
            <b class="m-0">{{ formatDate(task.attributes.deadline) }}</b>
          </b-col>
          <b-col v-if="isAdmin" class="mt-3">
            <b-button
              class="mt-3 edit-button"
              variant="outline-primary"
              v-if="task"
              @click="$refs['edit-task-modal'].showModal()"
              >Upraviť</b-button
            >
          </b-col>
          <b-col cols="12" class="mt-3 note-wrapper">
            <div class="form-group">
              <label>{{
                isAdmin ? "Odkaz pre študentov" : "Poznámka od učiteľa"
              }}</label>
              <textarea
                class="note-text mt-3 ml-1 p-2 w-100"
                :value="task.attributes.note"
                rows="5"
                maxlength="1000"
                placeholder="Voľný text"
                autocomplete="off"
                disabled
              ></textarea>
            </div>
          </b-col>
          <b-col v-if="isAdmin" cols="12" class="mt-3">
            <a
              v-if="quizPublicUrl"
              :href="quizPublicUrl"
              target="_blank"
              rel="noopener noreferrer"
              class="d-flex align-items-center w-color-primary"
            >
              <svg-external-link-icon />
              <span class="font-weight-bold">Ukážka kvízu v úlohe</span>
            </a>
          </b-col>
          <b-col
            cols="12"
            class="mt-3 d-flex align-items-center justify-content-center"
          >
            <b-button
              variant="primary"
              v-if="task && !completed"
              @click="navigateToCourse"
            >
              {{ isAdmin ? "Pozrieť kurz" : "Splniť úlohu" }}
            </b-button>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
    <div class="d-flex align-items-center pt-3">
      <div class="z-icon-wrapper border">
        <svg-graduation-cap-icon />
      </div>
      <div>
        <h1
          v-b-tooltip.top.hover.viewport
          class="ml-4 mb-0"
          :title="resultsTooltipText"
        >
          {{ isAdmin ? "Výsledky študentov" : "Môj výsledok" }}
          <svg-info-icon class="info-icon" />
        </h1>
      </div>
    </div>
    <b-row class="justify-content-center">
      <GroupPerformanceTable v-if="showTaskAnswers" :task="task" />
      <h1 v-else-if="isAdmin">{{ $t("no-task-responses") }}</h1>
      <h1 v-else>{{ $t("no-answers") }}</h1>
    </b-row>
    <TaskEditModal
      ref="edit-task-modal"
      @editTask="editTask"
      @removeTask="$refs['remove-task-modal'].showModal(task)"
    />
    <RemoveModal
      ref="remove-task-modal"
      v-if="task"
      header-text="remove-task-confirm"
      @remove="removeTask"
    >
      <p>
        {{ $t("remove-confirm") }}
        <b class="fw-bold">{{ task.attributes.course.data.attributes.name }}</b>
        ?
      </p>
    </RemoveModal>
  </b-container>
</template>

<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import { get, set } from "lodash";
import axios from "/utils/axios";
import { getCourseThumbnailUrl, getQuizFormUrls } from "/utils/helpers";
import * as Sentry from "@sentry/vue";

const api = {
  admin: {
    getTask: (taskId) => {
      return axios.getAuth(`tasks/${taskId}`, {
        sort: ["responses.groupMember.user.firstName"],
        params: {
          populate: [
            "group",
            "course.thumbnail",
            "course.quiz.form",
            "course.quiz.questions.answers",
            "responses.groupMember.user",
            "responses.quizResponse",
          ],
        },
      });
    },
    editTask: (taskId, task) => {
      return axios.putAuth(`tasks/${taskId}`, {
        data: task,
      });
    },
    removeTask: (taskId) => {
      return axios.deleteAuth(`tasks/${taskId}`);
    },
  },
  member: {
    getTask: (taskId, userId) => {
      return axios.getAuth(`tasks/${taskId}`, {
        sort: ["responses.groupMember.user.firstName"],
        params: {
          populate: {
            group: true,
            course: { populate: ["thumbnail", "quiz.questions.answers"] },
            responses: {
              populate: ["groupMember.user", "quizResponse"],
              filters: set({}, "groupMember.user.id.$eq", userId),
            },
          },
        },
      });
    },
  },
};

export default {
  layout: "profile",

  components: {
    GroupPerformanceTable: () =>
      import("/components/Group/GroupPerformanceTable.vue"),
    TaskEditModal: () => import("/components/Task/TaskEditModal.vue"),
    RemoveModal: () => import("/components/RemoveModal.vue"),
    "svg-arrow-left-icon": () => import("/assets/icons/arrowLeft.svg?inline"),
    "svg-bullet-icon": () => import("/assets/icons/bullet.svg?inline"),
    "svg-graduation-cap-icon": () =>
      import("/assets/icons/graduationCap.svg?inline"),
    "svg-external-link-icon": () =>
      import("/assets/icons/externalLink.svg?inline"),
    "svg-info-icon": () => import("/assets/icons/infoIcon.svg?inline"),
  },

  data() {
    return {
      task: null,
    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    isAdmin() {
      return this.user.type === "teacher";
    },
    resultsTooltipText() {
      return this.isAdmin
        ? "Ak chcete zistiť znenie otázok, prejdite kurzorom na číslo otázky."
        : "Ak chceš zistiť znenie otázok, prejdi kurzorom na číslo otázky.";
    },
    courseUrl() {
      return `/kurz/${this.task.attributes.course.data.attributes.slug}`;
    },
    started() {
      return moment().isSameOrAfter(this.task.attributes.startsAt);
    },
    active() {
      return moment().isBefore(this.task.attributes.deadline);
    },
    completed() {
      return !this.isAdmin && this.task.attributes.responses.data.length > 0;
    },
    quizPublicUrl() {
      return getQuizFormUrls({
        course: get(this.task, "attributes.course.data"),
        user: this.user,
      }).publicUrl;
    },
    showTaskAnswers() {
      return !!get(this.task, "attributes.responses.data.length");
    },
  },

  async created() {
    await this._loadTask();
  },

  methods: {
    formatDate(t) {
      return moment(t).utc().tz("Europe/Bratislava").format("DD.MM.YYYY HH:mm");
    },
    async removeTask() {
      this.$toast.clear();
      try {
        this.$toast.success();
        await api.admin.removeTask(this.$route.params.taskId);

        this.$router.push(`/triedy/${this.$route.params.id}`);
      } catch (error) {
        Sentry.captureException(error);
        this.$toast.error(error);
      }
    },
    async editTask(task) {
      this.$toast.clear();
      try {
        this.$toast.success("Zmena nastavenia úlohy úspešná");

        await api.admin.editTask(this.$route.params.taskId, {
          courseId: this.task.attributes.course.id,
          deadline: task.deadline,
          startsAt: task.startsAt,
        });

        this.$nextTick(async () => {
          await this._loadTask();
        });
      } catch (error) {
        Sentry.captureException(error);
        this.$toast.error(error);
      }
    },
    navigateToCourse() {
      this.$router.push(this.courseUrl);
    },
    courseThumbnail(course) {
      return getCourseThumbnailUrl(course);
    },
    async _loadTask() {
      const task = this.isAdmin
        ? await api.admin.getTask(this.$route.params.taskId)
        : await api.member.getTask(this.$route.params.taskId, this.user.id);
      this.task = task.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group input {
  font-weight: 100;
}
.cp {
  cursor: pointer;
}

.a-profile-subpage-title,
.note-wrapper {
  padding: 0;
}

.container {
  width: 90%;
}

h1 {
  font-size: 20px;
  color: #2b2e4a;
  line-height: normal;
  display: flex;
}

.edit-button {
  width: 100%;
  color: #86b2cd !important;
  border-color: #86b2cd !important;
  font-size: small;
  font-weight: bold;
  &:hover {
    background-color: #fb2564;
    color: #ffffff !important;
    border-color: #fb2564 !important;
  }
}

.cp {
  cursor: pointer;
}

.name-p {
  color: #2b2e4a;
  font-weight: lighter;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.status-p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 40px;
}

b {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 40px;
}
.cell-border {
  border-bottom: 1px solid #cadeea;
}

.light-blue {
  color: #86b2cd;
}

.group-name {
  color: #333333;
}

.note-text {
  resize: none;
  border: 1px solid rgba(156, 161, 174, 0.4);
}

.thumbnail {
  width: 76px;
  height: auto;
  border-radius: 4px;
}

.info-icon {
  margin: 0 0 5px 5px;
}
</style>
